/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
// import Modal from "@mui/material/Modal";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Divider from "@mui/material/Divider";
import { Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";

import { getLotRawMaterials, dispatchRawMaterials } from "network/service";
import { useNotification } from "context/notification.context";
import moment from "moment";


const material_headers = [
    { Header: "Item", accessor: "item", align: "center" },
    { Header: "Required", accessor: "required", align: "center" },
    { Header: "Available", accessor: "available", align: "center" },
    { Header: "Taken", accessor: "taken", align: "center" },
    // { Header: "UOM", accessor: "uom", align: "center" }
]



export default function TakeRawMaterials(props) {
    const [loading, setLoading] = useState(false);
    const { showNotification } = useNotification();


    const [materials, setMaterials] = useState([]);


    const formMaterialRows = (data) => {
        let result = [];
        console.log(data);
        for (let dt in data) {
            result.push({
                item: data[dt].Inventory.Product.ProductName,
                required: data[dt].Required,
                available: data[dt].QuantityOnHand,
                taken: <MDInput type="number" value={data[dt].Taken}
                    error={(parseFloat(data[dt].Taken) <= 0)}
                    onChange={(e) => {
                        let temp = [...data];
                        temp[dt].Taken = e.target.value;
                        setMaterials(temp);
                    }}></MDInput>,
                uom: ''
            });
        }
        return result
    }


    useEffect(() => {
        getLotRawMaterials(`_id=${props.details._id}`).then((resp) => {
            console.log(resp.data);
            setMaterials(resp.data);
        }).catch((err) => {
            console.log(err);
        })
    }, []);

    const validateBill = () => {
        let status = true;
        for (let mt of materials) {
            if (parseFloat(mt.Taken) <= 0) {
                status = false;
                break;
            }
        }
        return status;
    }

    const submit = () => {
        if (validateBill()) {
            setLoading(true);
            dispatchRawMaterials({
                Lot: props.details._id,
                Details: [...materials]
            }).then((resp) => {
                console.log(resp.data);
                setLoading(false);
                showNotification("success", "Success", "Raw materials dispatched successfully");
                props.handlesuccess();
            }).catch((err) => {
                console.log(err);
                setLoading(false);
                showNotification("error", "Failed", "Unable to dispatch raw materials");
            })
        }
    }

    return (
        <Dialog
            id="modal-display"
            open={props.isopen}
            aria-labelledby="form-dialog-title"
            aria-describedby="modal-modal-description"
            maxWidth="90%"
            maxHeight="90%"
            onClose={props.handleclose}
            disableBackdropClick
        >
            <MDBox>
                <DialogTitle>Raw Material Details</DialogTitle>
            </MDBox>
            <DialogContent sx={{ minWidth: 500 }}>
                <Grid container direction="column" spacing={2} px={2}>
                    <Grid item xs={12} xl={12} md={12} >
                        <Grid container spacing={2}>
                            <Grid item xl={4} md={4} xs={4}>
                                <MDInput label={"Lot No."} fullWidth disabled value={props.details.LotNumber} />
                            </Grid>
                            <Grid item xl={4} md={4} xs={4}>
                                <MDInput label={"Recipe"} fullWidth disabled value={props.details.Recipe.RecipeName} />
                            </Grid>
                            <Grid item xl={4} md={4} xs={4}>
                                <MDInput label={"Quantity"} fullWidth disabled value={props.details.Quantity} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} xl={12} md={12}>
                        <DataTable
                            table={{
                                columns: material_headers,
                                rows: formMaterialRows(materials)
                            }}
                            showTotalEntries={false}
                            isSorted={false}
                            noEndBorder
                            entriesPerPage={false}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <MDButton variant="gradient" color="error" onClick={props.handleclose}
                    disabled={loading}>
                    <Icon>close</Icon>&nbsp;
                    Close
                </MDButton>
                <MDButton variant="gradient" color="success"
                    disabled={loading}
                    onClick={() => { submit() }}>
                    <Icon>save</Icon>&nbsp;
                    Save
                </MDButton>
            </DialogActions>
        </Dialog >
    );
}