/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
// import Modal from "@mui/material/Modal";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FormGroup from "@mui/material/FormGroup";
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { FormHelperText, Icon, Input } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

import { getRecipes, getUOM, getRecipeUnit, getInventoryItems, addLot, getManufacturingPlants } from "network/service";
import { useNotification } from "context/notification.context";
import { number } from "prop-types";




export default function AddLotPopup(props) {
    const { showNotification } = useNotification();
    const [recipes, setRecipes] = useState([]);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [newField, setNewField] = useState({ recipe: "", quantity: "", lot: "", product: null, plant: "" });
    const [unit, setUnit] = useState("");
    const [uoms, setUoms] = useState([]);
    const [plant, setPlant] = useState({ disabled: false, unit: null });
    const [mfplants, setMfPlants] = useState([]);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        getUOM().then((resp) => {
            setUoms(resp.data);
        }).catch((err) => {
            console.log(err);
        })
    }, [setUoms]);

    useEffect(() => {
        getRecipes().then((resp) => {
            setRecipes(resp.data);
        }).catch((err) => {
            console.log(err);
        })
    }, [setRecipes]);

    useEffect(() => {
        if (newField.recipe) {
            for (let um of uoms) {
                if (um._id === newField.recipe.Product.UnitOfMeasure) {
                    setUnit(um.name);
                }
            }
        }
    }, [setUnit, newField.recipe])

    useEffect(() => {
        if (newField.recipe) {
            getInventoryItems(`Product=${newField.recipe.Product._id}`).then((resp) => {
                console.log(resp.data);
                setProducts(resp.data);
            }).catch((err) => {
                console.log(err);
            })
        }
    }, [setProducts, newField.recipe])

    useEffect(() => {
        if (newField.recipe) {
            getManufacturingPlants().then((resp1) => {
                setMfPlants(resp1.data);
            }).catch((err) => {
                console.log(err);
            });
        }
    }, [setMfPlants, newField.recipe])

    useEffect(() => {
        if (newField.product) {
            getRecipeUnit(`Recipe=${newField.recipe._id}`).then((resp) => {
                console.log(resp.data);
                if (resp.data.length > 0) {
                    for (let plnt of mfplants) {
                        console.log(plnt._id, resp.data[0].ManufacturingPlant._id);
                        if (plnt._id === resp.data[0].ManufacturingPlant._id) {
                            setPlant({ disabled: true, unit: plnt });
                            break;
                        }
                    }
                }
            }).catch((err) => {
                console.log(err);
            })
        }
    }, [setProducts, newField.product])

    const validate = () => {
        let isValid = true;
        let errors = {}

        if (!newField.recipe) return false;
        if (!newField.product) return false;
        if ((newField.quantity) <= 0) {
            errors.quantity = "Quanity is required"
            isValid = false;
        }

        setErrors(errors);
        return isValid;

    }


    const submit = async () => {

        if (validate()) {
            setLoading(true);
            addLot({
                ManufacturingPlant: plant.unit._id,
                Recipe: newField.recipe._id,
                LotNumber: newField.lot,
                Inventory: newField.product._id,
                Quantity: parseFloat(newField.quantity > 0),
                Comments: ""
            }).then((resp) => {
                console.log(resp.data);
                setLoading(false);
                props.handlesuccess({ ...newField });
            }).catch((err) => {
                setLoading(false);
                console.log(err);
            });
        }
    }


    return (
        <Dialog
            id="modal-display"
            open={props.isopen}
            aria-labelledby="form-dialog-title"
            aria-describedby="modal-modal-description"
            maxWidth="90%"
            maxHeight="90%"
            onClose={props.handleclose}
            disableBackdropClick
        >
            <MDBox>
                <DialogTitle>Add Lot</DialogTitle>
            </MDBox>
            <DialogContent sx={{ minWidth: 400 }}>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <MDInput
                            fullWidth
                            label="Lot No."
                            value={newField.lot}
                            onChange={(e) => {
                                setNewField({ ...newField, lot: e.target.value });
                            }}
                            helperText={
                                newField.lot === "" && (
                                    <FormHelperText sx={{ color: 'red', fontWeight: 800, margin: 0 }}>
                                        Lot No is required
                                    </FormHelperText>
                                )
                            }
                        />

                    </Grid>
                    <Grid item>
                        <Autocomplete
                            disableClearable
                            label={"Recipe"}
                            sx={{ flexGrow: 1 }}
                            options={recipes}
                            getOptionLabel={(option) => option.RecipeName || ''}
                            renderInput={(params) => <MDInput {...params} label={"Recipe"} fullWidth />}
                            onChange={(event, newValue) => {
                                setNewField({ ...newField, recipe: newValue });
                            }}
                            value={newField.recipe}

                        />

                        {(!newField.recipe || newField.recipe === 0) && (
                            <FormHelperText sx={{ color: 'red', fontWeight: 800, marginLeft: 2 }}>
                                Recipe is required
                            </FormHelperText>
                        )}

                    </Grid>
                    <Grid item>
                        <MDInput
                            fullWidth
                            label="Recipe Code"
                            disabled
                            value={newField.recipe ? newField.recipe.RecipeCode : ""}
                            onChange={(e) => {

                            }}

                        />

                    </Grid>
                    {/* <Grid item>
                        <MDInput
                            fullWidth
                            label="UOM"
                            disabled
                            value={unit}
                            onChange={(e) => {

                            }}
                        />

                    </Grid> */}
                    <Grid item>
                        <Autocomplete
                            disableClearable
                            label={"Product"}
                            sx={{ flexGrow: 1 }}
                            options={products}
                            getOptionLabel={(option) => {
                                let label = option.Product.ProductName || '';
                                // if (option.isCollection) {
                                //     label += ` ${option.UnitsInCollection}x${option.QtyOfUnit}`
                                // }
                                return label;
                            }}
                            renderInput={(params) => <MDInput {...params} label={"Product"} fullWidth />}
                            onChange={(event, newValue) => {
                                setNewField({ ...newField, product: newValue });
                            }}
                            value={newField.product}
                        />
                        {
                            (!newField.product || newField.product === 0) && (
                                <FormHelperText sx={{ color: 'red', fontWeight: 800, marginLeft: 2 }}>
                                    product  is required
                                </FormHelperText>
                            )
                        }
                    </Grid>
                    <Grid item>
                        <Autocomplete
                            disableClearable
                            label={"Manufacturing Unit"}
                            sx={{ flexGrow: 1 }}
                            options={mfplants}
                            disabled={plant.disabled}
                            getOptionLabel={(option) => option.PlantName || ''}
                            renderInput={(params) => <MDInput {...params} label={"Manufacturing Unit"} fullWidth />}
                            onChange={(event, newValue) => {
                                setPlant({ ...plant, unit: newValue });
                            }}
                            value={plant.unit}

                        />
                    </Grid>
                    <Grid item>
                        <MDInput
                            label="Quantity"
                            fullWidth
                            type="number"
                            value={newField.quantity}
                            onChange={(e) => {
                                setNewField({
                                    ...newField,
                                    quantity: e.target.value,
                                });
                            }}
                        />
                        {((newField.quantity === "" || newField.quantity <= 0) && (
                            <FormHelperText sx={{ color: 'red', fontWeight: 800, marginLeft: 2 }}>
                                Quantity is required
                            </FormHelperText>
                        ))}
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                <MDButton variant="gradient" color="error" onClick={props.handleclose}
                    disabled={loading}>
                    <Icon>close</Icon>&nbsp;
                    Cancel
                </MDButton>
                <MDButton variant="gradient" color="success"
                    disabled={loading}
                    onClick={() => { submit() }}>
                    <Icon>save</Icon>&nbsp;
                    Save
                </MDButton>
            </DialogActions>
        </Dialog>

    )
};
